import styled from '@emotion/styled'
import { ArrowDown } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'

export const Scroll = memo(function Scroll() {
  return (
    <Container
      onClick={() =>
        window.scroll({
          top: window.innerHeight + 1,
          left: 0,
          behavior: 'smooth',
        })
      }
    >
      <Icon dial={5}>
        <ArrowDown />
      </Icon>
    </Container>
  )
})

const Container = styled.div`
  cursor: pointer;
  position: absolute;
  bottom: 3.125rem;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
  z-index: 5;
  &:hover {
    > div {
      border-color: transparent;
      background: ${({ theme }) => theme.colors.variants.neutralDark3};
    }
  }

  @media (max-width: 1199px) {
    display: none;
  }
`

const Icon = styled(FlexBox)`
  width: 4.5rem;
  height: 4.5rem;
  border: 0.0625rem solid ${rgba(theme.colors.variants.neutralLight5, 0.3)};
  border-radius: 50%;
  transition: 0.4s cubic-bezier(0, 0.55, 0.45, 1);

  svg {
    width: auto;
    height: 1rem;
    fill: none;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight5};
  }
`
